<template>
  <sign-page
    title-text="继续教育"
    :request="request"
    :column-list="columnList"
    @dataFileUpdate="dataFileUpdate"
    table-size="large"
    :title-menus="[{key: 'add', label: '新增'}]"
    :table-actions="tableActions"
    :form-parms-add="formParmsAdd"
    :form-parms-update="formParmsUpdate"
    :table-actions-fixed="true"
    :need-data-file="true">
    <fm-modal :mask-closable="false" v-model="openChang" width="1000px" v-if="openChang">
      <fm-title title-text="专业技术职务变动情况" @clickTitleMenu="clickTitleMenu" :title-menus="[{key: 'addChang', label: '新增'}]"></fm-title>
      <fm-table-new
        :simple-filter="true"
        export-file-name="专业技术职务变动情况"
        :columns="columnsChang"
        border="row"
        :data-list="choose ? choose.changDataList : []"
        :stripe="false"
        emptyPlaceholder="-">
        <table-actions slot="actions" @table-action="tableActionChang" slot-scope="{ row }" :data="tableActionsChang" :row="row"></table-actions>
      </fm-table-new>
    </fm-modal>
    <fm-form-dialog
      :mask-closable="false"
      form-title="专业技术职务变动情况"
      :open-dialog.sync="openChangForm"
      :form-parms="formParmsChang"
      :old-data="chooseChang"
      label-alone
      label-align="left"
      form-width="800px"
      @formSubmit="formSubmitChang"
      @handleClose="openChangForm = false">
    </fm-form-dialog>
    <fm-modal :mask-closable="false" v-model="openRegister" width="1000px" v-if="openRegister">
      <fm-title title-text="继续教育登记" @clickTitleMenu="clickTitleMenu" :title-menus="[{key: 'addRegister', label: '新增'}]"></fm-title>
      <fm-table-new
        :simple-filter="true"
        export-file-name="继续教育登记"
        :columns="columnsRegister"
        border="row"
        :data-list="choose ? choose.registerDataList : []"
        :stripe="false"
        emptyPlaceholder="-">
        <table-actions slot="actions" @table-action="tableActionRegister" slot-scope="{ row }" :data="tableActionsRegister" :row="row"></table-actions>
      </fm-table-new>
    </fm-modal>
    <fm-form-dialog
      :mask-closable="false"
      form-title="继续教育登记"
      :open-dialog.sync="openRegisterForm"
      :form-parms="formParmsRegister"
      :old-data="chooseRegister"
      label-alone
      label-align="left"
      form-width="800px"
      @formSubmit="formSubmitRegister"
      @handleClose="openRegisterForm = false">
    </fm-form-dialog>
  </sign-page>
</template>

<script>
import {
  workerEduContinueRequest as request
} from '../../api'

import TableActions from '@/components/base/TableActions'

import {
  dateOperating
} from '@/fmlib'

import {
  loadData,
  getSelectWorkerList,
  loadSelectWorkerList
} from '../../auth_lib'

export default {
  components: {TableActions},
  computed: {
    tableActionsChang () {
      return [{
        key: 'edit',
        label: '修改'
      },
      {
        key: 'del',
        label: '删除'
      }]
    },
    tableActionsRegister () {
      return [{
        key: 'edit',
        label: '修改'
      },
      {
        key: 'del',
        label: '删除'
      }]
    },
    columnsChang () {
      return [{
        title: '专业技术职务名称',
        field: 'zyjszwmc'
      },
      {
        title: '任职开始时间',
        field: 'startDate',
        render: (h, rowData) => {
          return h('div', rowData && rowData.startDate ? rowData.startDate.slice(0, 10) : '-')
        }
      },
      {
        title: '任职结束时间',
        field: 'endDate',
        render: (h, rowData) => {
          return h('div', rowData && rowData.endDate ? rowData.endDate.slice(0, 10) : '-')
        }
      },
      {
        title: '操作',
        slot: 'actions',
        fixed: 'right',
        search: false,
        export: false,
        configurable: false
      }]
    },
    columnsRegister () {
      let data = this.formParmsRegister.map(v => {
        let item = {
          title: v.label,
          field: v.key
        }
        if (v.type === 'yearPicker') {
          item.render = (h, rowData) => {
            return h('div', rowData && rowData[v.key] ? rowData[v.key].slice(0, 4) : '-')
          }
        }
        return item
      })
      data.push({
        title: '操作',
        slot: 'actions',
        fixed: 'right',
        search: false,
        export: false,
        configurable: false
      })
      return data
    },
    tableActions () {
      let vm = this
      let data = [{
        key: 'chang',
        label: '专业技术职务变动情况',
        dealFun: (pageVm, parm2) => {
          vm.pageVm = pageVm
          vm.choose = Object.assign({}, parm2)
          vm.openChang = true
        }
      },
      {
        key: 'register',
        label: '登记',
        dealFun: (pageVm, parm2) => {
          vm.pageVm = pageVm
          vm.choose = Object.assign({}, parm2)
          vm.openRegister = true
        }
      },
      {
        key: 'data_file',
        label: '扫描件'
      },
      {
        key: 'edit',
        label: '修改'
      },
      {
        key: 'del',
        label: '删除'
      }]
      return data
    },
    formParmsRegister () {
      return [{
        type: 'input',
        label: '继续教育项目名称',
        key: 'xmmc'
      },
      // {
      //   type: 'input',
      //   label: '学习形式',
      //   key: 'xxxs'
      // },
      // {
      //   type: 'datePicker',
      //   label: '开始时间',
      //   key: 'startDate'
      // },
      {
        type: 'yearPicker',
        label: '所属年份',
        key: 'endDate'
      },
      {
        type: 'input',
        label: '总学时',
        key: 'xsTotal'
      },
      {
        type: 'input',
        label: '总学分',
        key: 'xfTotal'
      // },
      // {
      //   type: 'input',
      //   label: '认定单位',
      //   key: 'rddw'
      // },
      // {
      //   type: 'datePicker',
      //   label: '审核时间',
      //   key: 'shsj'
      // },
      // {
      //   type: 'select',
      //   label: '完成情况',
      //   key: 'wcqk',
      //   selectDatas: [{key: '已完成', label: '已完成'}, {key: '未完成', label: '未完成'}]
      // },
      // {
      //   type: 'input',
      //   label: '验证单位',
      //   key: 'yzdw'
      }]
    },
    formParmsChang () {
      return [{
        type: 'input',
        label: '专业技术职务名称',
        key: 'zyjszwmc'
      },
      {
        type: 'datePicker',
        label: '任职开始时间',
        key: 'startDate'
      },
      {
        type: 'datePicker',
        label: '任职结束时间',
        key: 'endDate'
      }]
    },
    formParmsAdd: {
      get () {
        let data = [{
          type: 'select',
          label: '所属员工',
          key: 'workerId',
          selectDatas: getSelectWorkerList(this),
          check: {
            required: true
          }
        },
        {
          type: 'input',
          label: '证书编号',
          key: 'zsCode'
        },
        {
          type: 'input',
          label: '发证机关',
          key: 'issueOrg'
        },
        {
          type: 'datePicker',
          label: '发证日期',
          key: 'issueDate'
        },
        {
          type: 'input',
          label: '工作单位',
          key: 'workeOrg'
        },
        {
          type: 'input',
          label: '专业技术岗位',
          key: 'zyjsgw'
        },
        {
          type: 'input',
          label: '专业技术职务',
          key: 'zyjszw'
        }]
        return data
      }
    },
    formParmsUpdate: {
      get () {
        let data = []
        this.formParmsAdd.forEach((item) => {
          let newItem = {}
          for (let key in item) {
            newItem[key] = item[key]
          }
          data.push(newItem)
        })
        return data
      }
    },
    columnList: {
      get () {
        let data = [{
          title: '员工姓名',
          field: 'workerName',
          sort: true
        },
        {
          title: '员工工号',
          field: 'workerCode',
          sort: true
        },
        {
          title: '证书编号',
          field: 'zsCode',
          sort: true
        },
        {
          title: '发证机关',
          field: 'issueOrg',
          sort: true
        },
        {
          title: '发证日期',
          field: 'issueDate',
          sort: true,
          width: 250,
          dataType: Date,
          filterRange: true,
          render: (h, rowData) => {
            return h('div', rowData && rowData.issueDate ? rowData.issueDate.slice(0, 10) : '-')
          },
          text: (rowData) => {
            return rowData && rowData.issueDate ? rowData.issueDate.slice(0, 10) : '-'
          }
        },
        {
          title: '工作单位',
          field: 'workeOrg',
          sort: true
        },
        {
          title: '专业技术岗位',
          field: 'zyjsgw',
          sort: true
        },
        {
          title: '专业技术职务',
          field: 'zyjszw',
          sort: true
        }]
        return data
      }
    }
  },
  created () {
    if (this.$authFunsProxy.add || this.$authFunsProxy.edit) {
      loadSelectWorkerList(this)
    }
  },
  methods: {
    async loadData (parm) {
      let data = await loadData(request, this, parm)
      data.forEach(v => {
        v.changDataList = v.changData ? JSON.parse(v.changData) : []
        v.registerDataList = v.registerData ? JSON.parse(v.registerData) : []
      })
      return data
    },
    async dataFileUpdate (parm, pageVm) {
      await pageVm.request.update(pageVm.chooseData.id, {
        fileIds: parm ? parm.join(',') : ''
      })
      pageVm.loadData()
    },
    clickTitleMenu (key) {
      if (key === 'addChang') {
        this.chooseChang = null
        this.openChangForm = true
      } if (key === 'addRegister') {
        this.chooseRegister = null
        this.openRegisterForm = true
      }
    },
    tableActionChang (parm) {
      this.chooseChang = parm.data
      if (parm.action === 'edit') {
        this.openChangForm = true
      } else if (parm.action === 'del') {
        this.delChangData()
      }
    },
    tableActionRegister (parm) {
      this.chooseRegister = parm.data
      if (parm.action === 'edit') {
        this.openRegisterForm = true
      } else if (parm.action === 'del') {
        this.delRegisterData()
      }
    },
    async delChangData () {
      const result = await this.$dialog.confirm({title: '系统提示', content: '确定删除该专业技术职务变动情况吗?'})
      if (result) {
        this.choose.changDataList = this.choose.changDataList.filter((v) => this.chooseChang.id !== v.id)
        await request.update(this.choose.id, {
          changData: JSON.stringify(this.choose.changDataList)
        })
        await this.pageVm.loadData()
        this.choose = this.pageVm.dataList.find(v => v.id === this.choose.id)
      }
    },
    async delRegisterData () {
      const result = await this.$dialog.confirm({title: '系统提示', content: '确定删除该继续教育登记吗?'})
      if (result) {
        this.choose.registerDataList = this.choose.registerDataList.filter((v) => this.chooseRegister.id !== v.id)
        await request.update(this.choose.id, {
          registerData: JSON.stringify(this.choose.registerDataList)
        })
        await this.pageVm.loadData()
        this.choose = this.pageVm.dataList.find(v => v.id === this.choose.id)
      }
    },
    timeBj (t1, t2) {
      return dateOperating.computeDay({days: 0, date: t1}) > dateOperating.computeDay({days: 0, date: t2})
    },
    dealFormData (data) {
      if (data.startDate && data.endDate && this.timeBj(data.startDate, data.endDate)) {
        this.$notice.info({
          title: '系统提示',
          desc: '结束时间需要大于开始时间。'
        })
        throw Error()
      }
      return data
    },
    async formSubmitChang (data, resolve) {
      try {
        this.dealFormData(data)
      } catch (error) {
        resolve()
        return
      }
      for (let key in data) {
        if (data[key] instanceof Date) {
          data[key] = dateOperating.computeDay({days: 0, date: data[key], format: 'yy-mm-dd hh:mm:ss'})
        }
      }
      if (data.id !== null && data.id !== undefined) {
        let i = 0
        while (i < this.choose.changDataList.length) {
          if (this.choose.changDataList[i].id === data.id) {
            this.choose.changDataList[i] = Object.assign({}, this.choose.changDataList[i], data)
          }
          i += 1
        }
        await request.update(this.choose.id, {
          changData: JSON.stringify(this.choose.changDataList)
        })
        await this.pageVm.loadData()
        this.choose = this.pageVm.dataList.find(v => v.id === this.choose.id)
      } else {
        data.id = new Date().getTime()
        this.choose.changDataList.push(data)
        await request.update(this.choose.id, {
          changData: JSON.stringify(this.choose.changDataList)
        })
        await this.pageVm.loadData()
        this.choose = this.pageVm.dataList.find(v => v.id === this.choose.id)
      }
      resolve()
      this.openChangForm = false
    },
    async formSubmitRegister (data, resolve) {
      try {
        this.dealFormData(data)
      } catch (error) {
        resolve()
        return
      }
      for (let key in data) {
        if (data[key] instanceof Date) {
          data[key] = dateOperating.computeDay({days: 0, date: data[key], format: 'yy-mm-dd hh:mm:ss'})
        }
      }
      if (data.id !== null && data.id !== undefined) {
        let i = 0
        while (i < this.choose.registerDataList.length) {
          if (this.choose.registerDataList[i].id === data.id) {
            this.choose.registerDataList[i] = Object.assign({}, this.choose.registerDataList[i], data)
          }
          i += 1
        }
        await request.update(this.choose.id, {
          registerData: JSON.stringify(this.choose.registerDataList)
        })
        await this.pageVm.loadData()
        this.choose = this.pageVm.dataList.find(v => v.id === this.choose.id)
      } else {
        data.id = new Date().getTime()
        this.choose.registerDataList.push(data)
        await request.update(this.choose.id, {
          registerData: JSON.stringify(this.choose.registerDataList)
        })
        await this.pageVm.loadData()
        this.choose = this.pageVm.dataList.find(v => v.id === this.choose.id)
      }
      resolve()
      this.openRegisterForm = false
    }
  },
  data () {
    return {
      choose: null,
      chooseChang: null,
      openChang: false,
      openChangForm: false,
      openRegister: false,
      openRegisterForm: false,
      chooseRegister: null,
      request: Object.assign({}, request, {
        get: this.loadData
      })
    }
  }
}
</script>

<style lang="less" scoped>
.img-c {
  height: 60vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  .no-img {
    font-size: 1.4rem;
    color: #999;
  }
  img {
    max-height: 100%;
    max-width: 100%;
  }
}
.file-d-footer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.file-d-footer-wx {
  display: none;
  justify-content: center;
  align-items: center;
}
.preview {
  background-color: #000;
  left: 0;
  top: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
  object-fit: contain;
  z-index: 99999;
  cursor: pointer;
}
</style>